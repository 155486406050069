<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Playbooks <span>Edit Offer Settings</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="playbook-offer-headline-form" class="offer-form">
                <div class="modal_body">
                    <div class="capsule_elm mb-3">
                        <h5>Display Offer</h5>
                        <label for="offer" class="switch_option capsule_btn">
                            <input type="checkbox" id="offer" :true-value="1" :false-value="0" v-model="form.display_playbook_offer" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <ul class="tab_sec mb-4">
                        <li @click="areaTab = 'headline'" :class="{ active: areaTab === 'headline' }">Headline</li>
                        <li @click="areaTab = 'settings'" :class="{ active: areaTab === 'settings' }">Settings</li>
                    </ul>
                    <div class="setting_wpr" v-if="areaTab === 'headline'">
                        <div class="capsule_elm">
                            <h5>Display Offer Headline</h5>
                            <label for="show_content_headline_v2" class="switch_option capsule_btn">
                                <input type="checkbox" id="show_content_headline_v2" :true-value="1" :false-value="0" v-model="form.display_offer_headline" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Offer Headline</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.offer_headline }">
                                    <Field autocomplete="off" name="offer_headline" type="text" placeholder="ex: Playbook Offer" v-model="form.offer_headline" />
                                </div>
                                <ErrorMessage name="offer_headline" class="text-danger" />
                            </div>
                        </div>
                        <h3 class="sub_header2 mt-3">Headline Position</h3>
                        <div class="edit_section py-2">
                            <div class="capsule_elm border-bottom">
                                <h5>Left Align?</h5>
                                <label for="left" class="switch_option capsule_btn">
                                    <input type="radio" id="left" value="left" v-model="form.offer_headline_position" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="capsule_elm border-bottom">
                                <h5>Center Align?</h5>
                                <label for="center" class="switch_option capsule_btn">
                                    <input type="radio" id="center" value="center" v-model="form.offer_headline_position" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="capsule_elm">
                                <h5>Right Align?</h5>
                                <label for="right" class="switch_option capsule_btn">
                                    <input type="radio" id="right" value="right" v-model="form.offer_headline_position" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="setting_wpr" v-if="areaTab === 'settings'">
                        <h3 class="sub_header2">Cover Image</h3>
                        <image-library v-model="form.offer_image" image-type="offer-popup" upload-text="Cover" />
                        <h3 class="sub_header2 mt-5">Offer Option</h3>
                        <div class="edit_section py-2">
                            <div class="capsule_elm border-bottom">
                                <h5>Redirect</h5>
                                <label for="redirect" class="switch_option capsule_btn">
                                    <input type="radio" id="redirect" value="redirect" v-model="form.offer_option" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="capsule_elm">
                                <h5>Popup</h5>
                                <label for="popup" class="switch_option capsule_btn">
                                    <input type="radio" id="popup" value="popup" v-model="form.offer_option" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                        </div>
                        <template v-if="form.offer_option === 'redirect'">
                            <div class="form_grp mt-3">
                                <div class="group_item">
                                    <label class="input_label">Select link</label>
                                    <div class="field_wpr">
                                        <multiselect
                                            v-model="link"
                                            :groups="true"
                                            :searchable="true"
                                            :options="links"
                                            valueProp="id"
                                            label="title"
                                            placeholder="Select link"
                                            :groupSelect="false"
                                            @select="handleLinkSelect"
                                            :groupHideEmpty="true"
                                        >
                                            <template v-slot:option="{ option }">
                                                <span v-if="option.$isLabel">
                                                    {{ option.$groupLabel }}
                                                </span>
                                                <span v-else>{{ option.title }}</span>
                                        </template>
                                        </multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Redirect Url</label>
                                    <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.offer_url }">
                                        <Field autocomplete="off" type="text" name="offer_url" v-model="form.offer_url" rules="url" label="redirect_url" placeholder="ex: https://superfitcoaching.com" />
                                        <span class="prefix">URL</span>
                                    </div>
                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                    <ErrorMessage name="offer_url" class="text-danger" />
                                </div>
                            </div>
                        </template>
                        <div v-if="form.offer_option === 'popup'">
                            <ul class="tab_sec mt-5 mb-2">
                                <li @click="popupTab = 'offer'" :class="{ active: popupTab === 'offer' }">Offer Settings</li>
                                <li @click="popupTab = 'notification'" :class="{ active: popupTab === 'notification' }">Notification</li>
                                <li @click="popupTab = 'optin'" :class="{ active: popupTab === 'optin' }">Opt-in Settings</li>
                            </ul>
                            <div v-if="popupTab === 'offer'">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Teaser Text</label>
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.offer_teaser }">
                                            <Field autocomplete="off" type="text" name="offer_teaser" v-model="form.offer_teaser" placeholder="ex: Offer Teaser" />
                                        </div>
                                        <ErrorMessage name="offer_teaser" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Teaser Headline</label>
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.offer_teaser_headline }">
                                            <Field autocomplete="off" type="text" name="offer_teaser_headline" v-model="form.offer_teaser_headline" placeholder="ex: Offer Teaser Headline" />
                                        </div>
                                        <ErrorMessage name="offer_teaser_headline" class="text-danger" />
                                    </div>
                                </div>
                                <h3 class="sub_header2 mt-3">Popup Image</h3>
                                <image-library v-model="form.offer_popup_image" image-type="offer-popup" upload-text="Cover" />
                                <div class="form_grp mb-2">
                                    <div class="group_item">
                                        <label class="input_label">Button Text</label>
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.offer_button_txt }">
                                            <Field autocomplete="off" type="text" name="offer_button_txt" v-model="form.offer_button_txt"  placeholder="ex: Offer Button" />
                                        </div>
                                        <ErrorMessage name="offer_button_txt" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button Color</label>
                                        <Field autocomplete="off" name="offer_btn_color" v-model="form.offer_btn_color" type="text" label="element color">
                                            <color-picker v-model="form.offer_btn_color" :classes="{ 'has-error': errors.offer_btn_color }" />
                                        </Field>
                                        <ErrorMessage name="offer_btn_color" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Button Text Color</label>
                                        <Field autocomplete="off" name="offer_btn_txtcolor" v-model="form.offer_btn_txtcolor" type="text" label="element color">
                                            <color-picker v-model="form.offer_btn_txtcolor" :classes="{ 'has-error': errors.offer_btn_txtcolor }" />
                                        </Field>
                                        <ErrorMessage name="offer_btn_txtcolor" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Select link</label>
                                        <div class="field_wpr">
                                            <multiselect
                                                v-model="link"
                                                :groups="true"
                                                :searchable="true"
                                                :options="links"
                                                valueProp="id"
                                                label="title"
                                                placeholder="Select link"
                                                :groupSelect="false"
                                                @select="handleLinkSelect"
                                                :groupHideEmpty="true"
                                            >
                                                <template v-slot:option="{ option }">
                                                    <span v-if="option.$isLabel">
                                                        {{ option.$groupLabel }}
                                                    </span>
                                                    <span v-else>{{ option.title }}</span>
                                            </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp pt-0 mt-3">
                                    <div class="group_item">
                                        <label class="input_label">Thank You Page URL</label>
                                        <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.offer_thankyou_url }">
                                            <Field autocomplete="off" type="text" name="offer_thankyou_url" v-model="form.offer_thankyou_url" rules="url" label="thank you page url" placeholder="ex: https://superfitcoaching.com" />
                                            <span class="prefix">URL</span>
                                        </div>
                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                        <ErrorMessage name="offer_thankyou_url" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="popupTab === 'notification'" class="pt-2">
                                <div class="capsule_elm">
                                    <h3 class="sub_heading2 m-0">{{notificationEmail.status ? 'Sending Method' : 'Notifications?'}}</h3>
                                    <label for="has-notification" class="switch_option capsule_btn">
                                        <input type="checkbox" id="has-notification" :true-value="1" :false-value="0" v-model="notificationEmail.status" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="section_wpr mb-2" v-if="notificationEmail.status">
                                    <div class="edit_section mb-2">
                                        <sending-method v-model="notificationEmail.option" />
                                    </div>
                                    <div v-if="notificationEmail.option == 1 || notificationEmail.option == 3">
                                        <email-component v-model="notificationEmail" :errors="errors" :handle-default-email="handleDefaultEmail" :has-recipient="true" ref="email-component" preview-module="playbook" />
                                    </div>
                                    <div v-if="notificationEmail.option == 2 || notificationEmail.option == 3">
                                        <sms-component v-model="notificationEmail.sms_message" :number="notificationEmail.sms_recipient" :handle-update="handleNotificationSmsChange" media-field-name="notif_sms_media" :has-number-field="true" preview-module="playbook" :handle-default-sms="handleDefaultSms" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="popupTab === 'optin'" class="pt-2">
                                <div class="capsule_elm">
                                    <h5>Opt-in</h5>
                                    <label for="offer-optin" class="switch_option capsule_btn m-0">
                                        <input type="checkbox" id="offer-optin" :true-value="1" :false-value="0" v-model="form.offer_optin" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <h3 class="sub_header2 pt-2 mt-2" v-if="form.offer_optin">Optin Fields</h3>
                                <ul class="optin_list" v-if="form.offer_optin">
                                    <li v-for="field of optinFields" :key="field.value">
                                        <label :for="`check${field.value}`" class="checkbox">
                                            <input type="checkbox" :id="`check${field.value}`" :value="field.value" :true-value="field.value" :false-value="''" v-model="form.offer_optin_fields" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                            <p>{{field.title}}</p>
                                        </label>
                                    </li>
                                </ul>
                                <h3 class="sub_header2 mt-5">Type</h3>
                                <div class="edit_section">
                                    <div class="capsule_elm border-bottom">
                                        <h5>Leads</h5>
                                        <label for="leads" class="switch_option capsule_btn">
                                            <input type="radio" id="leads" value="Prospect" v-model="form.offer_contacts_type" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="capsule_elm">
                                        <h5>Staff</h5>
                                        <label for="staff" class="switch_option capsule_btn">
                                            <input type="radio" id="staff" value="ActiveEmployee" v-model="form.offer_contacts_type" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                                <div class="form_grp mt-2">
                                    <div class="group_item">
                                        <label class="input_label">
                                            Add Tags
                                            <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                        </label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.offer_tags }">
                                            <Field autocomplete="off" name="offer_tags" v-model="form.offer_tags">
                                                <multiselect
                                                    v-model="form.offer_tags"
                                                    mode="tags"
                                                    label="name"
                                                    value-prop="id"
                                                    :searchable="true"
                                                    :group-select="true"
                                                    :options="tagsGroup"
                                                    :groups="true"
                                                    group-label="type"
                                                    group-options="tags"
                                                    :group-hide-empty="true"
                                                    placeholder="Select Tags"
                                                >
                                                    <template v-slot:grouplabel="{ group }">
                                                        <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <span>{{ option.name }}</span>
                                                    </template>
                                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                        <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                          {{ option.name }}
                                                          <span
                                                            v-if="!disabled"
                                                            class="multiselect-tag-remove"
                                                            @mousedown.prevent="handleTagRemove(option, $event)"
                                                          >
                                                              <span class="multiselect-tag-remove-icon"></span>
                                                          </span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="offer_tags" class="text-danger" />
                                    </div>
                                </div>
                                <div v-if="createTags" class="form_grp">
                                    <Form v-slot="{ errors }" ref="tag-form">
                                        <div class="group_item">
                                            <label class="input_label">Create a New Tag</label>
                                            <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                                <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." rules="required" />
                                                <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                            </div>
                                            <ErrorMessage name="name" class="text-danger" />
                                        </div>
                                    </Form>
                                </div>
                                <div class="capsule_elm">
                                    <h5>Award Points</h5>
                                    <label for="award" class="switch_option capsule_btn">
                                        <input type="checkbox" id="award" value="award" :true-value="1" :false-value="0" v-model="form.reward_status" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div v-if="form.reward_status">
                                    <div class="form_grp mt-2">
                                        <div class="group_item">
                                            <label class="input_label">Points Awarded</label>
                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.reward_points_awarded }">
                                                <Field autocomplete="off" name="reward_points_awarded" v-model="form.reward_points_awarded" rules="numeric" label="awarded points" type="number" value="0" />
                                            </div>
                                            <ErrorMessage name="reward_points_awarded" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="capsule_elm">
                                        <h5>Subscriber Reward Notifications</h5>
                                        <label for="reward_notify" class="switch_option capsule_btn">
                                            <input type="checkbox" id="reward_notify" :true-value="1" :false-value="0" v-model="form.reward_subscriber_notifications" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div v-if="form.reward_subscriber_notifications">
                                        <div class="section_wpr mb-2">
                                            <div class="edit_section">
                                                <sending-method v-model="form.reward_notification_method" />
                                            </div>
                                        </div>
                                        <div class="form_grp mt-2">
                                            <div class="group_item">
                                                <label class="input_label">You just earned 0 points for</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.reward_reason }">
                                                    <Field autocomplete="off" name="reward_reason" v-model="form.reward_reason" placeholder="Type here" type="text" />
                                                </div>
                                                <ErrorMessage name="reward_reason" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="playbookUpdateLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="playbookUpdateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="playbookUpdateLoader"></i> {{ playbookUpdateLoader ? 'Updating' : 'Update' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))
    const ColorPicker = defineAsyncComponent(() => import('@/components/ColorPicker'))
    const SendingMethod = defineAsyncComponent(() => import('@/components/SendingMethod'))
    const EmailComponent = defineAsyncComponent(() => import('@/components/EmailComponent'))
    const SmsComponent = defineAsyncComponent(() => import('@/components/SmsComponent'))

    import Multiselect from '@vueform/multiselect'

    export default {
        name: 'Playbook Offer Headline',

        data () {
            return {
                areaTab: 'headline',
                form: {
                    notif_sms_message: '',
                    display_offer_headline: 0,
                    offer_headline: '',
                    offer_headline_position: 'left',
                },
                tagForm: {
                    name: '',
                    type: 'leads',
                    uniqueId: 'bulk-action',
                },
                popupTab: 'offer',
                createTags: false,
                optinFields: [
                    {
                        title: 'First Name',
                        value: 'fname',
                    },
                    {
                        title: 'Last Name',
                        value: 'lname',
                    },
                    {
                        title: 'Phone',
                        value: 'phone',
                    },
                    {
                        title: 'Address',
                        value: 'address',
                    },
                    {
                        title: 'State',
                        value: 'state',
                    },
                    {
                        title: 'City',
                        value: 'city',
                    },
                    {
                        title: 'Country',
                        value: 'country',
                    },
                    {
                        title: 'Zip',
                        value: 'zip',
                    },
                    {
                        title: 'Date of Birth',
                        value: 'dob',
                    }
                ],
                notificationEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                notif_sms_media: [],
                link: '',
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ImageLibrary,
            ColorPicker,
            Multiselect,
            SendingMethod,
            EmailComponent,
            SmsComponent,
        },

        watch: {
            selectedPlaybook () {
                const vm = this;

                vm.resetForm();
            },

            'form.offer_option'(val){
                const vm = this;

                if (val) {
                    if (val == 'redirect' && vm.selectedPlaybook) {
                        vm.link = ''
                        vm.linksPublicURL = vm.links.map((items) => {
                            return items.options.find(link => link.public_url == vm.selectedPlaybook.offer_url);
                        });
                    } else if (val == 'popup' && vm.selectedPlaybook) {
                        vm.link = ''
                        vm.linksPublicURL = vm.links.map((items) => {
                            return items.options.find(link => link.public_url == vm.selectedPlaybook.offer_thankyou_url);
                        });
                    }

                    if (vm.linksPublicURL.length > 0) {
                        const publicURL = vm.linksPublicURL[0];

                        if (publicURL) {
                            vm.link = publicURL.id;
                        }
                    }
                }
            },
        },

        computed: mapState({
            selectedPlaybook: state => state.playbookModule.selectedPlaybook,
            playbookUpdateLoader: state => state.playbookModule.playbookUpdateLoader,
            tagsGroup: state => state.tagModule.tagsGroup,
            tagLoader: state => state.tagModule.tagLoader,
            user: state => state.authModule.user,
            links: state => state.commonModule.definedLinks,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                updatePlaybookSetting: 'playbookModule/updatePlaybookSetting',
                getTags: 'tagModule/getTags',
                createTag: 'tagModule/createTag',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    playbook_id: vm.selectedPlaybook.id,
                    display_playbook_offer: vm.selectedPlaybook.display_playbook_offer ? vm.selectedPlaybook.display_playbook_offer : 0,
                    offer_image: vm.selectedPlaybook.offer_image ? vm.selectedPlaybook.offer_image : '',
                    offer_option: vm.selectedPlaybook.offer_option ? vm.selectedPlaybook.offer_option : 0,
                    offer_url: vm.selectedPlaybook.offer_url ? vm.selectedPlaybook.offer_url : '',
                    offer_teaser: vm.selectedPlaybook.offer_teaser ? vm.selectedPlaybook.offer_teaser : '',
                    offer_teaser_headline: vm.selectedPlaybook.offer_teaser_headline ? vm.selectedPlaybook.offer_teaser_headline : '',
                    offer_popup_image: vm.selectedPlaybook.offer_popup_image ? vm.selectedPlaybook.offer_popup_image : '',
                    offer_button_txt: vm.selectedPlaybook.offer_button_txt ? vm.selectedPlaybook.offer_button_txt : '',
                    offer_btn_color: vm.selectedPlaybook.offer_btn_color ? vm.selectedPlaybook.offer_btn_color : '',
                    offer_btn_txtcolor: vm.selectedPlaybook.offer_btn_txtcolor ? vm.selectedPlaybook.offer_btn_txtcolor : '',
                    offer_thankyou_url: vm.selectedPlaybook.offer_thankyou_url ? vm.selectedPlaybook.offer_thankyou_url : '',
                    offer_optin: vm.selectedPlaybook.offer_optin ? vm.selectedPlaybook.offer_optin : 0,
                    offer_optin_fields: vm.selectedPlaybook.offer_optin_fields ? vm.selectedPlaybook.offer_optin_fields.split(',') : [],
                    offer_contacts_type: vm.selectedPlaybook.offer_contacts_type ? vm.selectedPlaybook.offer_contacts_type : 'Prospect',
                    offer_tags: vm.selectedPlaybook.offer_tags ? vm.selectedPlaybook.offer_tags : [],
                    reward_status: vm.selectedPlaybook.reward_status ? vm.selectedPlaybook.reward_status : 0,
                    reward_points_awarded: vm.selectedPlaybook.reward_points_awarded ? vm.selectedPlaybook.reward_points_awarded : '',
                    reward_subscriber_notifications: vm.selectedPlaybook.reward_subscriber_notifications ? vm.selectedPlaybook.reward_subscriber_notifications : '',
                    reward_notification_method: vm.selectedPlaybook.reward_notification_method ? parseInt(vm.selectedPlaybook.reward_notification_method) : 1,
                    reward_reason: vm.selectedPlaybook.reward_reason ? vm.selectedPlaybook.reward_reason : '',
                    display_offer_headline: vm.selectedPlaybook.display_offer_headline ? vm.selectedPlaybook.display_offer_headline : 0,
                    offer_headline: vm.selectedPlaybook.offer_headline ? vm.selectedPlaybook.offer_headline : '',
                    offer_headline_position: vm.selectedPlaybook.offer_headline_position ? vm.selectedPlaybook.offer_headline_position : 'left',
                };

                vm.tagForm = {
                    name: '',
                    type: 'leads',
                    uniqueId: 'playbook-offer',
                };

                vm.notificationEmail = {
                    defaultHandler: true,
                    subject: vm.selectedPlaybook.offer_notification ? vm.selectedPlaybook.offer_notification.email_subject : '',
                    email: vm.selectedPlaybook.offer_notification ? vm.selectedPlaybook.offer_notification.email_message : '',
                    option: vm.selectedPlaybook.offer_notification ? vm.selectedPlaybook.offer_notification.option : 0,
                    status: vm.selectedPlaybook.offer_notification ? vm.selectedPlaybook.offer_notification.status : 0,
                    is_email_logo: vm.selectedPlaybook.show_offer_email_logo,
                    is_signature: vm.selectedPlaybook.offer_notification ? vm.selectedPlaybook.offer_notification.is_signature : '',
                    signature_id: vm.selectedPlaybook.offer_notification ? vm.selectedPlaybook.offer_notification.signature_id : '',
                    show_email_logo: vm.selectedPlaybook.show_offer_email_logo,
                    sms_message: vm.selectedPlaybook.offer_notification ? vm.selectedPlaybook.offer_notification.sms_message : '',
                    sms_recipient: vm.selectedPlaybook.offer_notification && vm.selectedPlaybook.offer_notification.sms_recipient ? vm.selectedPlaybook.offer_notification.sms_recipient : vm.user.number,
                    sms_media: vm.selectedPlaybook.offer_notification ? vm.selectedPlaybook.offer_notification.sms_media : '',
                    email_logo: vm.selectedPlaybook.offer_email_logo,
                    recipient: vm.selectedPlaybook.offer_notification && vm.selectedPlaybook.offer_notification.email_recipient ? vm.selectedPlaybook.offer_notification.email_recipient : (vm.user.reply_to_email ? vm.user.reply_to_email : vm.user.email),
                    module_id: vm.selectedPlaybook.id,
                };

                vm.notif_sms_media = vm.selectedPlaybook.notif_sms_media ? vm.selectedPlaybook.notif_sms_media : [];
            },

            handleTagCreate () {
                const vm = this;
                const form = vm.$refs['tag-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.tagForm.setFieldError = form.setFieldError;

                        vm.createTag(vm.tagForm).then((result) => {
                            vm.createTags = false;
                            vm.tagForm.name = '';
                            vm.getTags();
                        });
                    }
                });
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.setFieldError          = setFieldError;
                params.offer_setting          = 1;

                params.offer_notification     = {
                                                    email_subject: vm.notificationEmail.subject,
                                                    email_recipient: vm.notificationEmail.recipient,
                                                    sms_message: vm.notificationEmail.sms_message,
                                                    sms_recipient: vm.notificationEmail.sms_recipient,
                                                    email_message: vm.notificationEmail.email,
                                                    option: vm.notificationEmail.option,
                                                    status: vm.notificationEmail.status,
                                                    is_signature: vm.notificationEmail.is_signature,
                                                    signature_id: vm.notificationEmail.signature_id,
                                                    sms_media: vm.notificationEmail.sms_media,
                                                };

                params.offer_email_logo       = vm.notificationEmail.email_logo;
                params.show_offer_email_logo  = vm.notificationEmail.show_email_logo;

                vm.updatePlaybookSetting(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },

            handleDefaultEmail () {
                const vm = this;
                let email = '<p>Hi {{company_owner}},</p>';
                    email += '<p>Someone just signed up for your {{playbook_name}} offer!</p>';
                    email += '<p>{{first_name}} {{last_name}}</p>';
                    email += '<p>{{phone}}</p>';
                    email += '<p>Click the link below to update their profile now.</p>';
                    email += '<p>{{contact_profile_link}}</p>';
                    email += '<br><p>Cheers!OM</p>';

                vm.notificationEmail.defaultEmail = true;
                vm.notificationEmail.subject      = 'Your offer was Accepted!';
                vm.notificationEmail.email        = email;
            },

            handleDefaultSms () {
                const vm = this;
                let sms = 'Someone just signed up for your {{playbook_name}} offer!\r\n{{first_name}} {{last_name}}\r\n{{phone}}\r\nClick the link below to update their profile now.\r\n{{contact_profile_link}}\r\nCheers!\r\nOM';

                vm.form.notif_sms_message = sms;
            },

            handleNotificationSmsChange (object) {
                const vm = this;

                vm.form.notif_sms_recipient = object.number;
            },

            handleLinkSelect (id, link) {
                const vm = this;

                vm.link  = link.id;
                if(vm.form.offer_option === 'redirect'){
                    vm.form.offer_url = link.public_url;
                }
                if(vm.form.offer_option === 'popup'){
                    vm.form.offer_thankyou_url  = link.public_url;
                }
            },
        },
    }
</script>

<style scoped>
    .offer-form {
        height: calc(100vh - 180px);
    }

    :deep(.setting_wpr .tab_content) {
        height: auto;
    }
</style>
